<template>
	<div>
		<b-modal
			id="modal-add-account-tag"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Add member account tag')"
			:no-close-on-backdrop="true"
			@ok="confirmAddMemberAccountTag"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addMemberAccountTag">
				<b-form
					ref=""
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationFormAccountTag"
				>
					<!-- Field: Tag Type -->
					<b-form-group
						label-for="tag-type"
					>
						<label class="mb-1"><span class="text-danger">*</span> {{ $t('Tag type') }}</label>
						<validation-provider
							#default="{ errors }"
							name="tagType"
							rules="required"
						>
							<v-select
								v-model="accountTag.tagTypeId"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="tagTypeLists"
								label="name"
								:reduce="val => val.id"
								input-id="reason"
								@input="selectTagType"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<!-- Tags -->
					<validation-provider
						#default="{ errors }"
						name="tags"
						rules="required"
					>
						<b-form-group
							label-for="tags"
						>
							<label class="mb-1"><span class="text-danger">*</span> {{ $t('Tags') }}</label>
							<v-select
								:key="keyTag"
								v-model="accountTag.tagIds"
								multiple
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:clearable="false"
								:filterable="false"
								:options="accountTag.filterTags"
								:reduce="val => val.id"
								label="name"
								input-id="tags"
							/>

							<small class="text-danger">{{ errors[0] }}</small>
						</b-form-group>
					</validation-provider>

				</b-form>
			</validation-observer>

		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import useUsersTag from '../tag/useUsersTag'

export default {
	components: {
		BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		tagTypeLists: {
			type: Array,
			default: null,
		},
	},
	data() {
		return {
			accountTag: {
				userId: router.currentRoute.params.id,
				tagTypeId: '',
				tagIds: [],
				filterTags: [],
			},
			keyTag: 1,
		}
	},
	mounted() {
	},
	created() {
	},
	destroyed() {
	},
	methods: {
		selectTagType(value) {
			this.accountTag.tagIds = []
			const find = this.tagTypeLists.find(item => item.id == value)
			this.accountTag.filterTags = find.tags
			this.keyTag += 1
		},
		validationFormAccountTag() {
			this.$refs.addMemberAccountTag.validate().then(success => {
				if (success) {
					this.addUserTag(this.accountTag)
				}
			})
		},
		async addUserTag(userTagData) {
			await this.$store.dispatch('app-user/addUserTag', userTagData)
				.then(response => {
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide('modal-add-account-tag')
									this.resetModal()
								}, '1000')
							})
							this.$emit('refetch-data')
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		},
		resetModal() {
			this.accountTag.tagTypeId = ''
			this.accountTag.tagIds = []
			this.accountTag.filterTags = []
		},
		confirmAddMemberAccountTag(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationFormAccountTag()
		}
	},
	setup() {
		const { usersTags } = useUsersTag()

		return {
			usersTags,
		}
	},
}
</script>